import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import MetroFares from "../components/metro-fares"

class FA1ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="FA1"
          description="View route information and buy your ticket for the FA1 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">FA1 service</h1>
              <p>Wide Lane near Dewsbury Road - Farnley Academy</p>
              <p>Farnley Academy - Wide Lane at Bantam Grove</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="FA1 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1uXAMWZE41It5KuVUewxNup58daWVp11V"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Farnley Academy
                </p>
                <p>
                From Wide Lane, Middleton Road, Fountain Street, Corporation Street, A643,
                Asquith Avenue, Victoria Road, Asquith Avenue, Branch End, Town Street, Church
                Street, Scott Green, Moorland Avenue, Spring Avenue, Church Street, Street Lane,
                East View, Street Lane, Gelderd Road, Wakefield Road, Moorside Road, B6125,
                Station Road, B6125, A58, Whitehall Road, A58, Branch Road, Ring Road Low
                Wortley, Whincover Drive, Butterbowl Drive.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="FA1 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1V_Uq1vs3cPIPoEl0nQhQaF9EWNQscTY&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Farnley Academy
                </p>
                <p>
                From Whincover Drive, Ring Road Lower Wortley, Ring Road Low Wortley, A58,
                Whitehall Road, A58, A650, A58, B6125, Station Road, B6125, Moorside Road,
                Wakefield Road, Gelderd Road, Street Lane, Church Street, Spring Avenue,
                Moorland Avenue, B6126, Church Street, B6126, Town Street, Branch End, Asquith
                Avenue, Victoria Road, Bruntcliffe Lane, Corporation Street, Fountain Street,
                Middleton Road, Wide Lane.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Wide Lane near Dewsbury Road</td>
                      <td>DEP</td>
                      <td>0715</td>
                    </tr>
                    <tr>
                      <td>Victoria Road School</td>
                      <td>DEP</td>
                      <td>0723</td>
                    </tr>
                    <tr>
                      <td>Gildersome Moorland Ave</td>
                      <td>DEP</td>
                      <td>0730</td>
                    </tr>
                    <tr>
                      <td>Drighlington Common</td>
                      <td>DEP</td>
                      <td>0745</td>
                    </tr>
                    <tr>
                      <td>Old Farnley</td>
                      <td>ARR</td>
                      <td>0800</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Old Farnley</td>
                      <td>DEP</td>
                      <td>1510</td>
                    </tr>
                    <tr>
                      <td>Drighlington Crossroads</td>
                      <td>DEP</td>
                      <td>1522</td>
                    </tr>
                    <tr>
                      <td>Hodgson Lane</td>
                      <td>DEP</td>
                      <td>1527</td>
                    </tr>
                    <tr>
                      <td>Spring Avenue Moorland Avenue</td>
                      <td>DEP</td>
                      <td>1540</td>
                    </tr>
                    <tr>
                    <td>Asquith Avenue near Deanfield Avenue</td>
                    <td>DEP</td>
                    <td>1547</td>
                  </tr>
                    <tr>
                      <td>Wide Lane at Bantam Grove</td>
                      <td>ARR</td>
                      <td>1555</td>
                    </tr>
                  </tbody>
                </table>
                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default FA1ServicePage
